import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import * as coreLocales from "@mui/material/locale";
import * as gridLocales from "@mui/x-data-grid";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { muiTheme, externalFonts } from "./muiTheme";
import "./types/gigya";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "./i18n/config";

const locale = "enUS";
const coreLocale = coreLocales[locale];
const gridLocale = gridLocales[locale];

const queryClient = new QueryClient();

void new Promise<void>((r) => {
  window.onGigyaServiceReady = () => {
    r();
  };

  const s = document.createElement("script");
  s.type = "text/javascript";
  s.src = `https://cdns.${process.env.REACT_APP_CDC_DC!}/JS/gigya.js?apikey=${process.env.REACT_APP_CDC_API_KEY!}`;
  document.querySelector("head")!.appendChild(s);
}).then(() => {
  ReactDOM.createRoot(document.getElementById("root-team-management")!).render(
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <Suspense>
          <ThemeProvider theme={createTheme(muiTheme, coreLocale, gridLocale)}>
            <HelmetProvider>
              <div>
                <Helmet>
                  {externalFonts.map((f) => (
                    <link key={f} href={f} rel="stylesheet" />
                  ))}
                </Helmet>
              </div>
            </HelmetProvider>

            <CssBaseline />
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ThemeProvider>
        </Suspense>
      </React.StrictMode>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>,
  );
});
