import { jsx } from "@emotion/react";
import { CompactTable } from "@table-library/react-table-library/compact";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useQuery } from "@tanstack/react-query";
import { GigyaGroupModels } from "../types/gigya.schema";
import { GroupInfo, ResponseSystemFields } from "../types/gigya";

type MemberProfile = {
  country: string;
  email: string;
  firstName: string;
  languages: string;
  lastName: string;
  locale: string;
};

type MemberRelationshipData = {
  role: string;
};

type Member = {
  UID: string;
  groupId: string;
  lastUpdated: string;
  lastUpdatedTimestamp: string;
  memberSince: string;
  memberSinceTimestamp: string;
  model: string;
  permissions: string;
  profile: MemberProfile;
  relationshipData: MemberRelationshipData;
};

type TableMembersProps = {
  currentUser?: Member;
  members?: Member[];
};

// IMPLEMENT CONTEXT FOR CURRENT USER, MEMBERS, API METHODS
// IMPLEMENT REACT API
export const TableMembers = (props?: TableMembersProps) => {
  const {
    isPending: isPendingMembers,
    error: errorMembers,
    data: members,
    isFetching: isFetchingMembers,
  } = useQuery({
    queryKey: ["members"],
    // queryFn: async () => {
    //   // TODO
    //   const response = await fetch("");
    //   return await response.json();
    // },
    queryFn: async () => {
      let response:
        | any[]
        | ({ results: GroupInfo<GigyaGroupModels>[] } & ResponseSystemFields)
        | PromiseLike<any[]> = [];
      await window.gigya.accounts.groups.getAllMemberGroups({
        callback: (memberGroupResponse) => {
          response = memberGroupResponse;
          console.log("MEMBERS: ", memberGroupResponse);
        },
      });
      console.log("RESPONSE: ", response);
      return response;
    },
  });

  // let groupId = undefined;

  if (members) {
    // groupId = members.results[0].groupId;
  }

  // const {
  //   isPending: isPendingMember,
  //   error: errorMember,
  //   data: member,
  //   isFetching: isFetchingMember,
  // } = useQuery({
  //   queryKey: ["member", groupId],
  //   queryFn: async () => {
  //     await window.gigya.accounts.groups.getGroupMemberInfo({
  //       model: GigyaGroupModels.ClinicModel,
  //       groupId: groupId,
  //       callback: (memberInfoResponse) => {
  //         return memberInfoResponse;
  //       },
  //     });
  //   },
  // });

  const handleChangeRole = (event: SelectChangeEvent) => {
    console.log("ROLE CHANGE: ", event.target.value);
  };

  const renderDropDownRole = (member: Member, isDifferentUser: boolean) => {
    if (isDifferentUser) {
      return (
        <Select
          id="member-role-id"
          value={member.relationshipData.role}
          // label=""
          onChange={handleChangeRole}
        >
          <MenuItem value="Admin">Admin</MenuItem>
          <MenuItem value="Member">Member</MenuItem>
        </Select>
      );
    } else {
      return (
        <Select
          id="member-role-id"
          value={member.relationshipData.role}
          // label=""
          onChange={handleChangeRole}
        >
          <MenuItem value="Admin">Admin</MenuItem>
        </Select>
      );
    }
  };

  const renderCellName = (member: Member) => {
    return `${member.profile.lastName}, ${member.profile.firstName}`;
  };

  const renderCellRole = (member: Member) => {
    // Only admins are able to view this tab. Admins are not allowed to change their own role.
    // Therefore, if the UID of the memeber is different from the currently logged in user,
    // give them both options. Otherwise, give them one and/or make the cell read-only.
    renderDropDownRole(member, member.UID !== member.UID);
  };

  const renderCellEmail = (member: Member) => {
    return `${member.profile.email}`;
  };

  const renderCellDelete = () => {
    // TODO
  };

  const COLUMNS = [
    {
      label: "Name",
      renderCell: renderCellName,
    },
    {
      label: "Role",
      renderCell: renderCellRole,
    },
    {
      label: "Email",
      renderCell: renderCellEmail,
    },
    {
      label: "",
      renderCell: renderCellDelete,
    },
  ];

  // if (isPendingMembers) return "Loading Members...";
  // if (isPendingMember) return "Loading Member...";

  // if (errorMembers)
  //   return "A members error has occurred: " + errorMembers.message;
  // if (errorMember)
  //   return "An member error has occurred: " + errorMember.message;

  // console.log("MEMEBRS: ", members, "MEMBER: ", member);

  // return <CompactTable colummns={COLUMNS} data={members} />;
  return <></>;
};
