import "./Empty.css";
import {
  REACT_APP_TST_REDIRECT_URL,
  REACT_APP_PRD_REDIRECT_URL,
  REACT_APP_DEV_REDIRECT_URL,
} from "../constants";

const ENVIRONMENT: string = process.env.REACT_APP_ENVIRONMENT || "dev";

const fetchRedirectUrl = () => {
  const TST = "tst";
  const PRD = "prd";
  if (ENVIRONMENT === TST) return REACT_APP_TST_REDIRECT_URL || "";
  if (ENVIRONMENT === PRD) return REACT_APP_PRD_REDIRECT_URL || "";
  return REACT_APP_DEV_REDIRECT_URL || "";
};

export const Empty = () => {
  return (
    <div className="tm">
      <div className="container">
        <div className="center-div">
          <h1>{`Welcome to Team Management!`}</h1>
          <p className="p1">{`This is where team administrators, like clinic owners and office managers, will be able to control which tools each team member has access to. It seems we haven't released this tool to your clinic yet, or you are not yet a part of a Team.`}</p>
          <p className="p2">{`We'll be in touch when you get access!`}</p>
          <p className="p2">
            {`For now, head back to `}
            <a href={fetchRedirectUrl()}>{`Hillsvet.com`}</a>
          </p>
        </div>
      </div>
    </div>
  );
};
