import type React from "react";
import { useLocation, useNavigate, useParams, Router } from "react-router-dom";

/** @deprecated Use `React Router hooks` instead */
export type WithRouterProps = {
  router: {
    location: ReturnType<typeof useLocation>;
    params: Record<string, string>;
    navigate: ReturnType<typeof useNavigate>;
  };
};

/** @deprecated Use `React Router hooks` instead */

export const withRouter = <Props extends WithRouterProps>(
  Component: React.ComponentType<Props>,
) => {
  const Wrapper = (props: Omit<Props, keyof WithRouterProps>) => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    return (
      <Component
        {...(props as Props)}
        router={{ location, params, navigate }}
      />
    );
  };

  return Wrapper;
};
