import React from 'react'

import { Helmet } from 'react-helmet'

import './p11440311h-g-m-home-adminmember-viewgroupdetailstabclon.css'

const P11440311HGMHomeAdminmemberViewgroupdetailstabclon = (props) => {
  return (
    <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-container">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-p11440311h-g-m-home-adminmember-viewgroupdetailstabclon">
        <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-header">
          <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-primary">
            <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-navigation-logo">
              <img
                src="/playground_assets/logobackground1060-4sm.svg"
                alt="LogoBackground1060"
                className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-logo-background"
              />
              <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text DesktopH3">
                <span>Vet</span>
              </span>
              <img
                src="/playground_assets/simple1060-1iy.svg"
                alt="Simple1060"
                className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-simple"
              />
            </div>
            <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-link-items">
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-navigation-links">
                <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text002">
                  <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text003 UIObjectsText3Bold">
                    <span>Products</span>
                  </span>
                </div>
                <img
                  src="/playground_assets/directionexpand1060-qadw.svg"
                  alt="directionexpand1060"
                  className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-directionexpand"
                />
              </div>
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-navigation-links1">
                <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text005">
                  <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text006 UIObjectsText3Bold">
                    <span>Ordering</span>
                  </span>
                </div>
                <img
                  src="/playground_assets/directionexpand1060-uri8.svg"
                  alt="directionexpand1060"
                  className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-directionexpand1"
                />
              </div>
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-navigation-links2">
                <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text008">
                  <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text009 UIObjectsText3Bold">
                    <span>Resources</span>
                  </span>
                </div>
                <img
                  src="/playground_assets/directionexpand1060-otz.svg"
                  alt="directionexpand1060"
                  className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-directionexpand2"
                />
              </div>
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-navigation-links3">
                <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text011">
                  <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text012 UIObjectsText3Bold">
                    <span>Education</span>
                  </span>
                </div>
                <img
                  src="/playground_assets/directionexpand1060-xgnp.svg"
                  alt="directionexpand1060"
                  className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-directionexpand3"
                />
              </div>
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-navigation-links4">
                <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text014">
                  <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text015 UIObjectsText3Bold">
                    <span>Contact</span>
                  </span>
                </div>
                <img
                  src="/playground_assets/directionexpand1060-osc.svg"
                  alt="directionexpand1060"
                  className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-directionexpand4"
                />
              </div>
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-navigation-links5">
                <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text017"></div>
              </div>
            </div>
          </div>
          <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame-utility">
            <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-navigation-links6">
              <img
                src="/playground_assets/search1060-to6.svg"
                alt="search1060"
                className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-search"
              />
            </div>
            <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-navigation-links7">
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-navigation-links8">
                <img
                  src="/playground_assets/person1060-5zzg.svg"
                  alt="person1060"
                  className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-person"
                />
              </div>
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame1">
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text018 UIObjectsText3Bold">
                  <span>Dan Thompson</span>
                </span>
              </div>
              <img
                src="/playground_assets/directionexpand1060-0h8.svg"
                alt="directionexpand1060"
                className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-directionexpand5"
              />
            </div>
          </div>
        </div>
        <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame51">
          <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-section1">
            <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-textandsupportingtext">
              <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text020 DesktopH2">
                <span>Informational Details</span>
              </span>
              <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text022 Paragraph1">
                <span>View or update your Team’s details here.</span>
              </span>
            </div>
          </div>
          <img
            src="/playground_assets/rectanglecopy1060-pe2j-200h.png"
            alt="RectangleCopy1060"
            className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy"
          />
        </div>
        <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame334">
          <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame61651401">
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-textandsupportingtext2">
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text616028 DesktopH3">
                  <span>Name</span>
                </span>
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-textM616001 Paragraph1">
                  <span>John Doe</span>
                </span>
              </div>
            <img
              src="/playground_assets/rectanglecopy1060-p7z-200h.png"
              alt="RectangleCopy1060"
              className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy4"
            />
          </div>
          <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame54">
            <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame511">
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-textandsupportingtext1">
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text024 DesktopH3">
                  <span>Team Official Name</span>
                </span>
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text026 Paragraph1">
                  <span>College Blvd Animal Hospital</span>
                </span>
              </div>
            </div>
            <img
              src="/playground_assets/rectanglecopy1060-8j-200h.png"
              alt="RectangleCopy1060"
              className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy1"
            />
          </div>
          <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame541">
            <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame512">
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-textandsupportingtext2">
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text028 DesktopH3">
                  <span>Team Nickname</span>
                </span>
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text030 Paragraph1">
                  <span>College Campus</span>
                </span>
              </div>
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-content">
                <button className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame-button">
                  <button className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-parent-button">
                    <button className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-button-internal">
                      <img
                        src="/playground_assets/editi1060-sl4.svg"
                        alt="editI1060"
                        className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-edit"
                      />
                      <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frametextbox">
                        <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text032 UIObjectsButton">
                          <span>Edit</span>
                        </span>
                      </div>
                    </button>
                  </button>
                </button>
              </div>
            </div>
            <img
              src="/playground_assets/rectanglecopy1060-muaj-200h.png"
              alt="RectangleCopy1060"
              className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy2"
            />
          </div>
          {/* <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame542">
            <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame513">
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-textandsupportingtext3">
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text034 DesktopH3">
                  <span>Team Type</span>
                </span>
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text036 Paragraph1">
                  <span>Vet Clinic</span>
                </span>
              </div> 
            </div>
            <img
              src="/playground_assets/rectanglecopy1060-da1-200h.png"
              alt="RectangleCopy1060"
              className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy3"
            />
          </div> */}
          <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame61651402">
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-textandsupportingtext2">
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text616028 DesktopH3">
                  <span>Role</span>
                </span>
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-textM616002 Paragraph1">
                  <span>Member</span>
                </span>
              </div>
            <img
              src="/playground_assets/rectanglecopy1060-p7z-200h.png"
              alt="RectangleCopy1060"
              className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy4"
            />
          </div>
          <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame61651403">
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-textandsupportingtext2">
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text616028 DesktopH3">
                  <span>Status</span>
                </span>
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-textM616003 Paragraph1">
                  <span>Aproved</span>
                </span>
              </div>
            <img
              src="/playground_assets/rectanglecopy1060-p7z-200h.png"
              alt="RectangleCopy1060"
              className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy4"
            />
          </div>
          <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame514">
            <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-textandsupportingtext4">
              <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text040 DesktopH3">
                <span>Hill’s “Ship To” Account Number</span>
              </span>
              <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text042 Paragraph1">
                <span>574891759</span>
              </span>
            </div>
            <img
              src="/playground_assets/rectanglecopy1060-p7z-200h.png"
              alt="RectangleCopy1060"
              className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy4"
            />
          </div>
          <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame335">
            <button className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-button335">
              <button className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-parent-button335">
                <button className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-internal-button335">
                  <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-container4">
                    <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text335 UIObjectsButton">
                      <span>Leave This Team</span>
                    </span>
                  </div>
                </button>
              </button>
            </button>
          </div>
        </div>
        <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame330">
          <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame329">
            <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text044 DesktopH1">
              <span>Team Management</span>
            </span>
            <button className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame-button2">
              <button className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-parent-button2">
                <button className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-button-internal2">
                  <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frametextbox2">
                    <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text046 UIObjectsButton">
                      <span>Join another Team</span>
                    </span>
                  </div>
                </button>
              </button>
            </button>
          </div>
          <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text048 Paragraph1">
            <span>Select a team to access its details below.</span>
          </span>
          <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text-field1on-white1single-linewith-icona-inactive-copy">
            <img
              src="/playground_assets/container1060-4f9-200h.png"
              alt="Container1060"
              className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-container1"
            />
            <img
              src="/playground_assets/state1060-ewx2-500w.png"
              alt="State1060"
              className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-state"
            />
            <img
              src="/playground_assets/focus1060-bp3-500w.png"
              alt="Focus1060"
              className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-focus"
            />
            <img
              src="/playground_assets/icon1060-3kdu.svg"
              alt="Icon1060"
              className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-icon"
            />
            <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text050 Paragraph2">
              <span>College Blvd Animal Hospital</span>
            </span>
            <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text052">
              <span>Selected Team</span>
            </span>
          </div>
          <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-group50">
            <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-tabs">
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-scrollable-tab-text-elements">
                <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-tab">
                  <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-tab-content">
                    <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text054 UIObjectsText3BOLDCAPS-2">
                      <span>Team Details</span>
                    </span>
                  </div>
                  <img
                    src="/playground_assets/tabbar1060-dm2c-200h.png"
                    alt="TabBar1060"
                    className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-tab-bar"
                  />
                </div>
              </div>
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-scrollable-tab-text-elements1">
                <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-tab1">
                  <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-tab-content1">
                    <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text056 UIObjectsText3BOLDCAPS-2">
                      <span>Members</span>
                    </span>
                  </div>
                  <img
                    src="/playground_assets/tabbar1060-0jjv-200h.png"
                    alt="TabBar1060"
                    className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-tab-bar1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-footer">
          <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-footer-legal">
            <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-utility-links">
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame83">
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text058 UIObjectsText4">
                  <span>Copyright © 2022 Hill’s. All rights reserved.</span>
                </span>
                <img
                  src="/playground_assets/rectangle1060-s52-200w.png"
                  alt="Rectangle1060"
                  className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle"
                />
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text060 UIObjectsText4">
                  <span>Legal &amp; Privacy Policy</span>
                </span>
                <img
                  src="/playground_assets/rectanglecopy231060-rqy-200w.png"
                  alt="RectangleCopy231060"
                  className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy23"
                />
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text062 UIObjectsText4">
                  <span>Terms &amp; Conditions</span>
                </span>
                <img
                  src="/playground_assets/rectanglecopy231060-wbh8-200w.png"
                  alt="RectangleCopy231060"
                  className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy231"
                />
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text064 UIObjectsText4">
                  <span>Do Not Sell My Personal Information</span>
                </span>
                <img
                  src="/playground_assets/rectanglecopy231060-l7sg-200w.png"
                  alt="RectangleCopy231060"
                  className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy232"
                />
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text066 UIObjectsText4">
                  <span>Site Map</span>
                </span>
                <img
                  src="/playground_assets/rectanglecopy231060-qyoo-200w.png"
                  alt="RectangleCopy231060"
                  className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy233"
                />
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text068 UIObjectsText4">
                  <span>Cookie Consent Tool</span>
                </span>
              </div>
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame326">
                <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame831">
                  <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-country">
                    <img
                      src="/playground_assets/vectori1060-juza.svg"
                      alt="vectorI1060"
                      className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-vector"
                    />
                  </div>
                  <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text070 UIObjectsText4">
                    <span>Country [English]</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-horizon">
            <img
              src="/playground_assets/brandhorizon1060-3cf8.svg"
              alt="BrandHorizon1060"
              className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-brand-horizon"
            />
            <img
              src="/playground_assets/brandhorizon1060-mj6gk.svg"
              alt="BrandHorizon1060"
              className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-brand-horizon1"
            />
            <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-connect-with-us">
              <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text072 UIObjectsText3Bold">
                <span>Connect With Us</span>
              </span>
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-social-icons">
                <img
                  src="/playground_assets/facebook1060-mt4h.svg"
                  alt="Facebook1060"
                  className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-facebook"
                />
                <img
                  src="/playground_assets/twitter1060-450c.svg"
                  alt="Twitter1060"
                  className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-twitter"
                />
                <img
                  src="/playground_assets/facebook1060-hal8.svg"
                  alt="Facebook1060"
                  className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-facebook1"
                />
                <img
                  src="/playground_assets/youtube1060-dux9.svg"
                  alt="youtube1060"
                  className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-youtube"
                />
              </div>
            </div>
          </div>
          <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame9">
            <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-about-hills">
              <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text074 UIObjectsText3Bold">
                <span>About Hill’s</span>
              </span>
              <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text076 UIObjectsText3">
                <span>Our Company</span>
              </span>
              <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text078 UIObjectsText3">
                <span>Hill’s Pet</span>
              </span>
              <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text080 UIObjectsText3">
                <span>Careers</span>
              </span>
            </div>
            <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-our-food-brands">
              <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text082 UIObjectsText3Bold">
                <span>Our Food Brands</span>
              </span>
              <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text084 UIObjectsText3">
                <span>Hill’s Retail Order</span>
              </span>
              <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text086 UIObjectsText3">
                <span>VIP Market</span>
              </span>
              <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text088 UIObjectsText3">
                <span>Hill’s to Home</span>
              </span>
              <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text090 UIObjectsText3">
                <span>Download Order Forms</span>
              </span>
            </div>
            <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-support">
              <div className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame327">
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text092 UIObjectsText3Bold">
                  <span>Support</span>
                </span>
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text094 UIObjectsText3">
                  <span>Contact Us</span>
                </span>
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text096 UIObjectsText3">
                  <span>Manage Your Profile</span>
                </span>
                <span className="p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text098 UIObjectsText3">
                  <span>Clinic Application</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default P11440311HGMHomeAdminmemberViewgroupdetailstabclon
