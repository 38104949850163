import { Component } from "react";
import { type WithTranslation, withTranslation } from "react-i18next";
import {
  type GroupInfo,
  type GroupMemberInfo,
  isMemberAdmin,
  memberPermissions,
  adminPermissions,
  type ResponseSystemFields,
} from "../types/gigya";
import "../i18n/config";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Skeleton,
  Snackbar,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  useGridApiContext,
} from "@mui/x-data-grid";
import { ArrowDropDown } from "@mui/icons-material";
import { GigyaGroupModels } from "../types/gigya.schema";
import { transformExportedDesign } from "../muiConverter";
import P11440322HGMHomeAdminViewmemberstabedit from "./.exported_teleporthq/groups wip-react/src/views/p11440322h-g-m-home-admin-viewmemberstabedit";
import { ReactComponent as TrashSvg } from "./.exported_teleporthq/groups wip-react/public/playground_assets/trash6936-0alf.svg";
import ModalDialog from "./ModalDialog";
import { type ToastState, type ModalDialogState } from "../App";
import React from "react";
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";
import { TableMembers } from "./TableMembers";

type GroupMembersProps = {
  clinicInfo?: GroupInfo<GigyaGroupModels.ClinicModel>;
  user: any;
  onDirty: (d: boolean) => void;
} & WithTranslation;
class GroupMembers extends Component<
  GroupMembersProps,
  {
    members?: GroupMemberInfo[];
    updatedMembers?: GroupMemberInfo[];
    updatedPermissions?: Record<string, string>;
    modalDialog?: ModalDialogState;
    toast?: ToastState;
    spinner?: boolean;
    pendingMembers?: GroupMemberInfo[];
  }
> {
  constructor(props: GroupMembersProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchMembers();
  }

  componentDidUpdate(prevProps: GroupMembersProps) {
    if (
      this.props.clinicInfo &&
      (!this.state ||
        prevProps.clinicInfo?.groupId !== this.props.clinicInfo.groupId)
    ) {
      this.setState({
        members: undefined,
        updatedMembers: undefined,
        updatedPermissions: {},
        pendingMembers: undefined,
      });
      this.fetchMembers();
    }
  }

  handleModalDialogClose = () => {
    this.setState({ modalDialog: { open: false } });
  };

  handleToastClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ toast: { open: false } });
  };

  fetchMembers() {
    this.setState({ spinner: true });
    window.gigya.accounts.getJWT({
      UID: this.props.user.UID,
      callback: (resp) => {
        if (resp["errorCode"] === 0) {
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${resp.id_token}`,
          };
          const apiKey = process.env.REACT_APP_CDC_API_KEY ?? "";
          const GetMembersUrl = `${process.env.REACT_APP_CDC_API_BASEURL}/${process.env.REACT_APP_CDC_API_VERSION}/cdc/groups/getMembers`;
          axios
            .post(
              GetMembersUrl,
              {
                apiKey,
                groupId: this.props.clinicInfo!.groupId,
              },
              { headers },
            )
            .then((res) => {
              this.setState({
                members: res.data.results.filter(
                  (result: { relationshipData: { status: string } }) =>
                    result.relationshipData.status !== "pending",
                ),
                updatedMembers: res.data.results.filter(
                  (result: { relationshipData: { status: string } }) =>
                    result.relationshipData.status !== "pending",
                ),
                updatedPermissions: {},
                spinner: false,
                pendingMembers: res.data.results.filter(
                  (result: { relationshipData: { status: string } }) =>
                    result.relationshipData.status === "pending",
                ),
              });
              console.log(
                "PENDING: ",
                res.data.results.filter(
                  (result: { relationshipData: { status: string } }) =>
                    result.relationshipData.status === "pending",
                ),
              );
            })
            .catch((err) => {
              this.setState({
                toast: {
                  open: true,
                  severity: "error",
                  message: "No Authorization",
                  errorDetails: err.errorDetails,
                },
                spinner: false,
              });
            });
        } else {
          this.setState({ spinner: false });
        }
      },
    });
  }

  // Update the updatedMembers state property here. It will enable the save button
  // when the object is different from the members object.
  processRowUpdate = (
    updatedRow: GroupMemberInfo,
    originalRow: GroupMemberInfo,
  ) => {
    const newPermissions: Record<string, string> = {};
    const newMembers = this.state.updatedMembers?.map((member) => {
      if (member.UID === updatedRow.UID) {
        return updatedRow;
      }

      return member;
    });

    console.log(
      "UPDATED ROW: ",
      updatedRow,
      "ORIGINAL ROW: ",
      originalRow,
      "NEW PERMISSIONS: ",
      newPermissions,
      "NEW MEMBERS: ",
      newMembers,
    );

    // // Make sure that updatedPermissions is defined. This is done mostly to silence typescript errors
    // // but updatedPermissions should always be defined in the lifecycle methods before getting to this
    // // point
    // if (this.state.updatedPermissions !== undefined) {
    //   // If updatedPermissions has the UID as a property, create a new object without that property.
    //   // The value k === UID and v === permissions. If the UID is not a property in updatedPermissions
    //   // we want to add it. This will act as a toggle for adding/removing values whenever
    //   // a row update occurs.
    //   if (this.state.updatedPermissions[updatedRow.UID]) {
    //     Object.entries(this.state.updatedPermissions).forEach(([k, v]) => {
    //       if (updatedRow.UID !== k) {
    //         newPermissions[k] = v;
    //       }
    //     });
    //   } else {
    //     newPermissions = { ...this.state.updatedPermissions };
    //     newPermissions[updatedRow.UID] = updatedRow.permissions;
    //   }
    // }

    // // Update the component state. This update will update the conditional expression
    // // used to disable/enable the save button needed to update the database.
    // this.setState({
    //   updatedMembers: newMembers,
    //   updatedPermissions: newPermissions,
    // });

    return updatedRow;
  };

  dropdownComponent = (params: any) => {
    const ref = useGridApiContext();
    if (params.id === this.props.user.UID) {
      // ref.current.stopCellEditMode({
      // 	id: params.id,
      // 	field: params.field,
      // });
      return (
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
          style={{ opacity: "0.7" }}
        >
          {params.value}
        </div>
      );
    } else {
      return (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onClick={(event) => {
            event.stopPropagation(); // to not select row
            ref.current.startCellEditMode({
              id: params.id,
              field: params.field,
            });
          }}
        >
          <div>{params.value}</div>
          <ArrowDropDown
            key={1}
            color="primary"
            style={{ marginLeft: "auto" }}
            onClick={(event) => {
              event.stopPropagation(); // to not select row
              ref.current.startCellEditMode({
                id: params.id,
                field: params.field,
              });
            }}
          />
        </Box>
      );
    }
  };

  renderPendingRoleCell = (params: any) => {
    console.log("PARAMS: ", params);
    return (
      <div style={{ opacity: "0.7" }}>{params.row.relationshipData.role}</div>
    );
  };

  permissionsExist = () => {
    // If the state property is undefined return false
    if (this.state.updatedMembers === undefined) {
      return false;
    }

    // If the state property is not an empty object return true
    if (Object.keys(this.state.updatedMembers).length) {
      return true;
    }

    // If the state proprty is an empty object return false
    return false;
  };

  savePermission = () => {
    //No change done then updated permissions is an empty object hence return true
    if (JSON.stringify(this.state.updatedPermissions) == "{}") {
      return true;
    }
    return false;
  };

  handleDeclineMember = (member: GroupMemberInfo) => {
    // MAKE REQUEST THAT UPDATES STATUS TO PENDING FOR NOW.
    // DISCUSS THE DECLINED STATUS
    window.gigya.accounts.getJWT({
      UID: this.props.user.UID ?? "",
      callback: (resp) => {
        if (resp["errorCode"] === 0) {
          console.log("DECLINE 0");
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${resp.id_token}`,
          };
          const apiKey = process.env.REACT_APP_CDC_API_KEY ?? "";
          const SetMemberInfoUrl = `${process.env.REACT_APP_CDC_API_BASEURL}/${process.env.REACT_APP_CDC_API_VERSION}/cdc/groups/setMemberInfo`;
          axios
            .post(
              SetMemberInfoUrl,
              {
                apiKey,
                groupId: this.props.clinicInfo?.groupId,
                memberUid: member.UID,
                role: member.relationshipData.role,
                status: "pending",
              },
              { headers },
            )
            .then((res) => {
              console.log("DECLINE THEN");
              const newPendingMembers = this.state.pendingMembers?.filter(
                (pendingMember) => pendingMember.UID !== member.UID,
              );

              this.setState({
                spinner: false,
                pendingMembers: newPendingMembers,
              });
            })
            .catch((err) => {
              console.log("DECLINE CATCH: ", err);
              this.setState({
                toast: {
                  open: true,
                  severity: "error",
                  message: err.errorMessage,
                  errorDetails: err.errorDetails,
                },
                spinner: false,
              });
            });
        } else {
          console.log("DECLINE ELSE");
          this.setState({
            spinner: false,
          });
        }
      },
    });
  };

  handleAcceptMember = (member: GroupMemberInfo) => {
    // MAKE REQUEST THAT UPDATES STATUS TO APPROVED
    window.gigya.accounts.getJWT({
      UID: this.props.user.UID ?? "",
      callback: (resp) => {
        if (resp["errorCode"] === 0) {
          console.log("APPROVE 0");
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${resp.id_token}`,
          };
          const apiKey = process.env.REACT_APP_CDC_API_KEY ?? "";
          const SetMemberInfoUrl = `${process.env.REACT_APP_CDC_API_BASEURL}/${process.env.REACT_APP_CDC_API_VERSION}/cdc/groups/setMemberInfo`;
          axios
            .post(
              SetMemberInfoUrl,
              {
                apiKey,
                groupId: this.props.clinicInfo?.groupId,
                memberUid: member.UID,
                role: member.relationshipData.role,
                status: "approved",
              },
              { headers },
            )
            .then((res) => {
              console.log("APPROVE THEN");
              const newPendingMembers = this.state.pendingMembers?.filter(
                (pendingMember) => pendingMember.UID !== member.UID,
              );

              this.setState({
                spinner: false,
                pendingMembers: newPendingMembers,
              });
            })
            .catch((err) => {
              console.log("APPROVE CATCH");
              this.setState({
                toast: {
                  open: true,
                  severity: "error",
                  message: err.errorMessage,
                  errorDetails: err.errorDetails,
                },
                spinner: false,
              });
            });
        } else {
          console.log("APPROVE ELSE");
          this.setState({
            spinner: false,
          });
        }
      },
    });
  };

  render() {
    return [
      this.state.spinner ? (
        <LoadingSpinner key={0}></LoadingSpinner>
      ) : (
        React.cloneElement(
          transformExportedDesign(
            {
              "frame-button3": (
                <Button
                  disabled={this.savePermission()}
                  variant="text"
                  onClick={() => {
                    this.setState({ spinner: true });
                    this.fetchMembers();
                    this.setState({ spinner: false });
                  }}
                />
              ),
              "frame-button4": (
                <Button
                  disabled={this.savePermission()}
                  onClick={() => {
                    const sleep = async (ms: number) =>
                      new Promise((r) => {
                        setTimeout(r, ms);
                      });
                    Promise.all(
                      Object.entries(this.state.updatedPermissions!).map(
                        async ([k, v], i) => {
                          await sleep(200 * i);
                          window.gigya.accounts.getJWT({
                            UID: this.props.user.UID ?? "",
                            callback: (resp) => {
                              if (resp["errorCode"] === 0) {
                                const headers = {
                                  "Content-Type": "application/json",
                                  Authorization: `Bearer ${resp.id_token}`,
                                };
                                const apiKey =
                                  process.env.REACT_APP_CDC_API_KEY ?? "";
                                const SetMemberInfoUrl = `${process.env.REACT_APP_CDC_API_BASEURL}/${process.env.REACT_APP_CDC_API_VERSION}/cdc/groups/setMemberInfo`;
                                axios
                                  .post(
                                    SetMemberInfoUrl,
                                    {
                                      apiKey,
                                      groupId: this.props.clinicInfo?.groupId,
                                      memberUid: k,
                                      role:
                                        v === adminPermissions
                                          ? "admin"
                                          : "member",
                                    },
                                    { headers },
                                  )
                                  .then((res) => {
                                    this.setState({
                                      spinner: false,
                                    });
                                  })
                                  .catch((err) => {
                                    this.setState({
                                      toast: {
                                        open: true,
                                        severity: "error",
                                        message: err.errorMessage,
                                        errorDetails: err.errorDetails,
                                      },
                                      spinner: false,
                                    });
                                  });
                              } else {
                                this.setState({
                                  spinner: false,
                                });
                              }
                            },
                          });
                        },
                      ),
                    )
                      .then(() => {
                        this.setState({
                          updatedPermissions: {},
                        });
                      })
                      .catch((r: ResponseSystemFields) => {
                        this.setState({
                          toast: {
                            open: true,
                            severity: "error",
                            message: r.errorMessage,
                            errorDetails: r.errorDetails,
                          },
                          spinner: false,
                        });
                      });
                  }}
                />
              ),
              table: this.state?.members ? (
                <div style={{ width: "100%" }}>
                  <DataGrid
                    // sx={{'.MuiDataGrid-main': {width: '100%'}}}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 7,
                        },
                      },
                    }}
                    getRowId={(m) => m.UID}
                    rows={this.state?.members}
                    columnBuffer={4}
                    processRowUpdate={(updatedRow, originalRow) =>
                      this.processRowUpdate(updatedRow, originalRow)
                    }
                    columns={[
                      {
                        flex: 1,
                        headerName: this.props.t("exported.Name"),
                        field: "fullName",
                        valueGetter: (p) =>
                          `${p.row.profile.lastName as string}, ${p.row.profile.firstName as string}`,
                      },
                      {
                        flex: 0.3,
                        headerName: this.props.t("status"),
                        field: "status",
                        type: "singleSelect",
                        editable: true,
                        valueOptions: () => {
                          return [
                            {
                              value: "approved",
                              label: this.props.t("approved"),
                            },
                            {
                              value: "rejected",
                              label: this.props.t("rejected"),
                            },
                          ];
                        },
                        valueGetter: (p) =>
                          `${p.row.relationshipData.status as string}`,
                        renderCell: this.dropdownComponent,
                      },
                      {
                        flex: 0.3,
                        headerName: this.props.t("exported.Role"),
                        field: "admin",
                        type: "singleSelect",
                        editable: true,
                        valueOptions: (p) => {
                          if (p?.row?.UID != this.props.user.UID) {
                            return [
                              {
                                value: "Admin",
                                label: this.props.t("exported.Admin"),
                              },
                              {
                                value: "Member",
                                label: this.props.t("exported.Member"),
                              },
                            ];
                          } else {
                            return [
                              {
                                value: "Admin",
                                label: this.props.t("exported.Admin"),
                              },
                            ];
                          }
                        },
                        valueGetter: (p) =>
                          isMemberAdmin(p.row.permissions)
                            ? this.props.t("exported.Admin")
                            : this.props.t("exported.Member"),
                        valueSetter: (p) => {
                          const clonedMembers =
                            this.state.members ??
                            structuredClone(this.state.updatedMembers);
                          const newPermissions =
                            p.value === "Admin"
                              ? adminPermissions
                              : memberPermissions;
                          return {
                            ...p.row,
                            permissions: newPermissions,
                          };
                        },
                        renderCell: this.dropdownComponent,
                      },
                      {
                        flex: 1,
                        headerName: this.props.t("exported.Email"),
                        field: "email",
                        valueGetter: (p) => p.row.profile.email,
                      },
                      {
                        flex: 0.15,
                        headerName: "",
                        field: "action",
                        type: "actions",
                        getActions: (p) => [
                          <GridActionsCellItem
                            className="member-remove-trash"
                            id={`member-remove-trash-${p.id}`}
                            key="delete"
                            icon={<TrashSvg />}
                            sx={{
                              "&.Mui-disabled": {
                                pointerEvents: "auto",
                              },
                            }}
                            disabled={
                              p.row.relationshipData.role === "admin" &&
                              p.row.UID === this.props.user.UID
                                ? true
                                : false
                            }
                            title={
                              p.row.relationshipData.role === "admin" &&
                              p.row.UID === this.props.user.UID
                                ? this.props.t("members.warningAdmin")
                                : this.props.t("members.deleteUser")
                            }
                            label={this.props.t("members.deleteUser")}
                            onClick={() => {
                              this.setState({
                                modalDialog: {
                                  open: true,
                                  style: {
                                    height: "640px",
                                  },
                                  title: this.props
                                    .t(
                                      "members.Are you sure you want to delete this member from “College blvd Animal Hospital”?",
                                    )
                                    .replace(
                                      "College blvd Animal Hospital",
                                      this.props.clinicInfo!.groupData
                                        .clinicName as string,
                                    ),
                                  content: [
                                    <Typography
                                      key={0}
                                      variant="UIObjectsText1"
                                    >{`${this.props.t("exported.Name")}: ${p.row.profile.lastName as string}, ${p.row.profile.firstName as string}`}</Typography>,
                                    <Typography
                                      key={1}
                                      variant="UIObjectsText1"
                                    >{`${this.props.t("exported.Role")}: ${isMemberAdmin(p.row.permissions) ? this.props.t("exported.Admin") : this.props.t("exported.Member")}`}</Typography>,
                                    <Typography
                                      key={2}
                                      variant="UIObjectsText1"
                                    >{`${this.props.t("exported.Email")}: ${p.row.profile.email as string}`}</Typography>,
                                    <Typography
                                      key={3}
                                      variant="UIObjectsText1"
                                    >
                                      {this.props.t(
                                        "members.deleteMemberText1",
                                      )}
                                    </Typography>,
                                    <Typography
                                      key={4}
                                      variant="UIObjectsText1"
                                    >
                                      {this.props.t(
                                        "members.deleteMemberText2",
                                      )}
                                    </Typography>,
                                  ],
                                  actions: [
                                    <Button
                                      key={0}
                                      variant="text"
                                      onClick={this.handleModalDialogClose}
                                    >
                                      {this.props.t("exported.Cancel")}
                                    </Button>,
                                    <Button
                                      key={1}
                                      variant="text"
                                      onClick={() => {
                                        this.setState({
                                          spinner: true,
                                        });
                                        const removedUsers = [
                                          ...new Set([
                                            ...(this.props.clinicInfo!.groupData
                                              .removedUsers ?? []),
                                            p.row.UID,
                                          ]),
                                        ];
                                        Promise.all([
                                          new Promise((s, e) => {
                                            this.setState({
                                              modalDialog: {
                                                open: false,
                                              },
                                            });
                                            const val =
                                              this.state.members?.filter(
                                                (item) =>
                                                  item.relationshipData.role !=
                                                  undefined,
                                              );
                                            if (
                                              val != undefined &&
                                              val?.length > 0
                                            ) {
                                              window.gigya.accounts.getJWT({
                                                UID: val[0].UID ?? "",
                                                callback: (res) => {
                                                  if (res["errorCode"] === 0) {
                                                    const headers = {
                                                      "Content-Type":
                                                        "application/json",
                                                      Authorization: `Bearer ${res.id_token}`,
                                                    };
                                                    const apiKey =
                                                      process.env
                                                        .REACT_APP_CDC_API_KEY ??
                                                      "";
                                                    const RemoveMemberUrl = `${process.env.REACT_APP_CDC_API_BASEURL}/${process.env.REACT_APP_CDC_API_VERSION}/cdc/groups/removeMember`;
                                                    axios
                                                      .post(
                                                        RemoveMemberUrl,
                                                        {
                                                          apiKey,
                                                          groupId:
                                                            this.props
                                                              .clinicInfo
                                                              ?.groupId,
                                                          emailId:
                                                            p.row.profile.email,
                                                        },
                                                        {
                                                          headers,
                                                        },
                                                      )
                                                      .then(() => {
                                                        setTimeout(() => {
                                                          this.fetchMembers();
                                                          this.setState({
                                                            spinner: false,
                                                          });
                                                        }, 3000);
                                                      })
                                                      .catch(
                                                        (
                                                          r: ResponseSystemFields,
                                                        ) => {
                                                          this.setState({
                                                            toast: {
                                                              open: true,
                                                              severity: "error",
                                                              message:
                                                                r.errorMessage,
                                                              errorDetails:
                                                                r.errorDetails,
                                                            },
                                                            modalDialog: {
                                                              open: false,
                                                            },
                                                          });
                                                          setTimeout(() => {
                                                            this.fetchMembers();
                                                            this.setState({
                                                              spinner: false,
                                                            });
                                                          }, 3000);
                                                        },
                                                      );
                                                  }
                                                },
                                              });
                                            } else {
                                              this.setState({
                                                spinner: false,
                                              });
                                            }
                                          }),
                                          new Promise((s, e) => {
                                            window.gigya.accounts.groups.setGroupInfo(
                                              {
                                                model:
                                                  GigyaGroupModels.ClinicModel,
                                                groupId:
                                                  this.props.clinicInfo!
                                                    .groupId,
                                                groupData: {
                                                  removedUsers,
                                                },
                                                callback(r) {
                                                  (r.errorCode ? e : s)(r);
                                                },
                                              },
                                            );
                                          }),
                                        ])
                                          .then(() => {
                                            this.props.clinicInfo!.groupData.removedUsers =
                                              removedUsers;
                                            this.setState({
                                              updatedMembers:
                                                this.state.updatedMembers!.filter(
                                                  (v) => v.UID !== p.row.UID,
                                                ),
                                              modalDialog: {
                                                open: false,
                                              },
                                              spinner: false,
                                            });
                                          })
                                          .catch((r: ResponseSystemFields) => {
                                            this.setState({
                                              toast: {
                                                open: true,
                                                severity: "error",
                                                message: r.errorMessage,
                                                errorDetails: r.errorDetails,
                                              },
                                              modalDialog: {
                                                open: false,
                                              },
                                              spinner: false,
                                            });
                                          });
                                      }}
                                    >
                                      {this.props.t("members.Delete")}
                                    </Button>,
                                  ],
                                },
                              });
                            }}
                          />,
                        ],
                      },
                    ]}
                  />
                </div>
              ) : (
                <Skeleton height={50} />
              ),
              table2: this.state?.members ? (
                <div style={{ width: "100%" }}>
                  <DataGrid
                    // sx={{'.MuiDataGrid-main': {width: '100%'}}}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 7,
                        },
                      },
                    }}
                    getRowId={(m) => m.UID}
                    rows={this.state?.pendingMembers || []}
                    columnBuffer={4}
                    processRowUpdate={(updatedRow, originalRow) =>
                      this.processRowUpdate(updatedRow, originalRow)
                    }
                    columns={[
                      {
                        flex: 1,
                        headerName: this.props.t("exported.Name"),
                        field: "fullName",
                        valueGetter: (p) =>
                          `${p.row.profile.lastName as string}, ${p.row.profile.firstName as string}`,
                      },
                      {
                        flex: 0.3,
                        headerName: this.props.t("exported.Role"),
                        field: "admin",
                        renderCell: this.renderPendingRoleCell,
                      },
                      {
                        flex: 1,
                        headerName: this.props.t("exported.Email"),
                        field: "email",
                        valueGetter: (p) => p.row.profile.email,
                      },
                      {
                        flex: 0.5,
                        headerName: "",
                        field: "decline",
                        type: "actions",
                        getActions: (p) => [
                          <GridActionsCellItem
                            className="member-remove-trash"
                            id={`member-decline-${p.id}`}
                            key="decline"
                            icon={
                              <Button
                                variant="outlined"
                                sx={{
                                  color: "#f44336",
                                  border: "2px solid #f44336",
                                }}
                              >
                                {this.props.t("reject")}
                              </Button>
                            }
                            label={this.props.t("members.deleteUser")}
                            onClick={() => {
                              this.handleDeclineMember(p.row);
                            }}
                          />,
                        ],
                      },
                      {
                        flex: 0.5,
                        headerName: "",
                        field: "approve",
                        type: "actions",
                        getActions: (p) => [
                          <GridActionsCellItem
                            className="member-remove-trash"
                            id={`member-approve-${p.id}`}
                            key="approve"
                            icon={
                              <Button
                                variant="outlined"
                                sx={{
                                  color: "#66bb6a",
                                  border: "2px solid #66bb6a",
                                }}
                              >
                                {this.props.t("approve")}
                              </Button>
                            }
                            label={this.props.t("members.deleteUser")}
                            onClick={() => {
                              this.handleAcceptMember(p.row);
                            }}
                          />,
                        ],
                      },
                    ]}
                  />
                </div>
              ) : (
                <Skeleton height={50} />
              ),
              column: "removeAll",
              frame331: "removeAll",
              "frame-button2": "removeAll",
            },
            P11440322HGMHomeAdminViewmemberstabedit(),
          ),
          { key: 0 },
        )
      ) /* eslint-disable-line new-cap */,
      <ModalDialog
        key={1}
        open={this.state?.modalDialog?.open ?? false}
        onClose={this.handleModalDialogClose}
        style={this.state?.modalDialog?.style}
        title={this.state?.modalDialog?.title ?? ""}
        content={this.state?.modalDialog?.content ?? <></>}
        actions={this.state?.modalDialog?.actions ?? []}
      />,
      <TableMembers key={2} />,
    ];
  }
}

export default withTranslation()(GroupMembers);
