import {
  type GigyaUserRecord,
  type GigyaGroupSchemas,
  type GigyaGroupModels,
} from "../types/gigya.schema";
import { type RecursivePartial } from "../App";

export type groupDataSchema = "groupDataSchema"; // eslint-disable-line @typescript-eslint/naming-convention
export type relationshipDataSchema = "relationshipDataSchema"; // eslint-disable-line @typescript-eslint/naming-convention

declare global {
  interface Window {
    // eslint-disable-line @typescript-eslint/consistent-type-definitions
    gigya: Gigya;
    onGigyaServiceReady: () => void;
  }
}

export type Gigya = {
  accounts: {
    groups: {
      searchGroupMembers: <T extends GigyaGroupModels>(p?: {
        model: T;
        groupId: string;
        limit: number;
        callback?: (
          r: {
            results: GroupMemberInfo[];
          } & ResponseSystemFields,
        ) => void;
      }) => void;
      getAllMemberGroups: <T extends GigyaGroupModels>(p?: {
        callback?: (
          r: {
            results: Array<GroupInfo<T>>;
          } & ResponseSystemFields,
        ) => void;
      }) => void;
      getGroupMemberInfo: <T extends GigyaGroupModels>(p?: {
        model: T;
        groupId?: string;
        uid?: string;
        callback?: (
          r: {
            result: GroupMember;
          } & ResponseSystemFields,
        ) => void;
      }) => void;
      setGroupMemberInfo: <T extends GigyaGroupModels>(p?: {
        model: T;
        groupId: string;
        uid?: string;
        permissions?: string;
        relationshipData: RecursivePartial<
          GigyaGroupSchemas[T][relationshipDataSchema]
        >;
        callback?: (r: ResponseSystemFields) => void;
      }) => void;
      setGroupInfo: <T extends GigyaGroupModels>(p?: {
        model: T;
        groupId: string;
        groupData: RecursivePartial<GigyaGroupSchemas[T][groupDataSchema]>;
        callback?: (r: ResponseSystemFields) => void;
      }) => void;
      createInvitation: <T extends GigyaGroupModels>(p?: {
        model: T;
        groupId: string;
        callback?: (
          r: {
            invitationLink: string;
          } & ResponseSystemFields,
        ) => void;
      }) => void;
      removeMember: <T extends GigyaGroupModels>(p?: {
        model: string;
        groupId: string;
        uid: string;
        callback?: (r: ResponseSystemFields) => void;
      }) => void;
    };
    getJWT: <T extends GigyaGroupModels>(p?: {
      UID: string;
      callback?: (r: any) => void;
    }) => void;
  };
  hasSession(): Promise<boolean>;
};

export type GroupMemberInfo = {
  relationshipData: any;
  UID: string;
  permissions: string;
  profile: GigyaUserRecord["profileSchema"];
};

export type GroupMember = {
  profile?: GigyaUserRecord["profileSchema"];
  UID: string;
  groupId: string;
  model: string;
  lastUpdated: string;
  lastUpdatedTimestamp: string;
  memberSince: string;
  memberSinceTimestamp: string;
  permissions: string;
};

export type GroupInfo<T extends GigyaGroupModels> = {
  groupId: string;
  groupData: GigyaGroupSchemas[T][groupDataSchema];
  permissions?: string;
  relationshipData: GigyaGroupSchemas[T][relationshipDataSchema];
  memberSince: Date;
};

export type ResponseSystemFields = {
  errorCode: number;
  errorMessage: string;
  errorDetails: string;
  callId: string;
};

export type ClinicTypes = "VET CLINIC" | "shelter" | "petshop";

export const clinicTypesMap: Partial<Record<ClinicTypes, string>> = {
  "VET CLINIC": "Veterinary Clinic",
  shelter: "Shelter",
  petshop: "Pet Shop",
};

export type GroupPermission =
  | "groupRead"
  | "groupWrite"
  | "groupDelete"
  | "relationshipRead"
  | "relationshipWrite"
  | "membersRemove"
  | "membersInvite"
  | "setPermissions";
export const memberPermissions = Array<GroupPermission>(
  "groupRead",
  "relationshipRead",
).join();
export const adminPermissions = Array<GroupPermission>(
  "groupRead",
  "groupWrite",
  "relationshipRead",
  "relationshipWrite",
  "membersRemove",
  "membersInvite",
  "setPermissions",
).join();
export const isMemberAdmin = (permissions?: string) =>
  permissions?.includes("Write");

export const getGigyaValue = <T>(v: T | T[]) =>
  Array.isArray(v) ? (v.length ? v[0] : undefined) : v;

// Mock objects
export const mockGigya = {
  // eslint-disable-line @typescript-eslint/consistent-type-assertions
  accounts: {
    groups: {},
  },
} as Gigya;

export const mockResponseSystemFields: ResponseSystemFields = {
  errorCode: 0,
  errorMessage: "OK",
  errorDetails: "",
  callId: "123456",
};
