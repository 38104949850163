declare global {
  interface Window {
    dataLayer: Array<DataLayerEvent>;
  }
}

interface DataLayerEvent {
  event: string;
  user_type: string;
  method?: string;
  action: string;
}

window.dataLayer = window.dataLayer || [];

export const pushDataLayerEvent = (dataLayerEvent: DataLayerEvent) => {
  window.dataLayer.push(dataLayerEvent);
};
