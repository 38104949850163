/* GOOGLE TAG MANAGER OBJECTS */
export const EMPTY_MEMBERS = {
  event: "teams_management",
  user_type: "member",
  action: "error no team associated",
};

export const PAGE_TEAM_DETAILS = {
  event: "teams_management",
  user_type: "member",
  action: "error no team associated",
};

export const MEMBER_REMOVE = {
  event: "teams_management",
  user_type: "member",
  action: "error no team associated",
};

export const MEMBER_JOIN = {
  event: "teams_management",
  user_type: "member",
  action: "error no team associated",
};

export const MEMBER_LOGIN = {
  event: "teams_management",
  user_type: "member",
  action: "error no team associated",
};
